import { Injectable } from '@angular/core';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { LoadUserAction } from 'app/profile/shared/store/user/user.actions';
import { cloneDeep } from 'lodash';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AddDogForCareModel } from '../models/add-dog-for-care.model';
import { AddPersonForCareModel } from '../models/add-person-for-care.model';
import { DogFilterModel } from '../models/dog-filter.model';
import { GetDogForCareModel, MembershipCardModel } from '../models/get-dog-for-care.model';
import { GetPersonForCareModel } from '../models/get-person-for-care.model';
import { PersonFilterModel } from '../models/person-filter.model';
import { SearchFormModel } from '../models/search-form-model';
import { CareService } from '../services/care.service';
import {
    ActivationChangePublication, AddDogForCare, AddPersonForCare, AddToFavourite, CareImageChange, CareImageDelete,
    DeleteMyPublication, EditDogForCare, EditPersonForCare, FeedbackFormSubmit, GetAllMyPublications, GetSinglePublication,
    PublishMyPublication, ScrollAction, SearchPersonOrDog, SetActiveTabIndex, SetCurrentStep, SetFilterOptions, SetLoaderState, SetMainTabIndex,
    SetMembershipCards,
    SetNullDataAction, SetPersonOrDogForm, SetSearchDataForm, SetSearchTab, SetSkipIndex
} from './care.actions';
import { DISPLAY_CARD_TYPE } from 'app/contants';
import { AuthService } from 'app/auth/services/auth.service';

export interface CareStateModel {
    error?: any;
    includeMembershipCards?: boolean,
    loading: boolean;
    activeTab: number;
    isDogAdd: boolean | undefined;
    currentStep: number;
    dogDetail?: GetDogForCareModel;
    personData?: GetPersonForCareModel;
    mainTabIndex: number;
    allPublications?: Array<GetDogForCareModel | GetPersonForCareModel>;
    dogFilterOption?: DogFilterModel;
    personFilterOption?: PersonFilterModel;
    totalFetchedRecords: number;
    searchData: Array<GetDogForCareModel | GetPersonForCareModel | MembershipCardModel>;
    searchInsideData?: Array<GetDogForCareModel | GetPersonForCareModel>;
    searchOutSideData?: Array<GetDogForCareModel | GetPersonForCareModel>;
    searchTab: string;
    searchDataForm?: SearchFormModel;
    skipIndex: number;
    isMoreData: boolean;
    outsideRadiusAvailable: boolean;
    totalCount: number;
    insideCount: number;
    partialCount: number;
    insideRegion: number;
    isAddressAvailable: boolean;
}

const defaultState: CareStateModel = {
    error: undefined,
    includeMembershipCards: false,
    loading: false,
    activeTab: 0,
    isDogAdd: undefined,
    currentStep: 1,
    dogDetail: undefined,
    personData: undefined,
    mainTabIndex: 1,
    allPublications: undefined,
    dogFilterOption: undefined,
    personFilterOption: undefined,
    totalFetchedRecords: 0,
    searchData: [],
    searchInsideData: [],
    searchOutSideData: [],
    searchTab: 'general',
    searchDataForm: undefined,
    skipIndex: 0,
    isMoreData: false,
    outsideRadiusAvailable: false,
    totalCount: 0,
    insideCount: 0,
    partialCount: 0,
    insideRegion: 0,
    isAddressAvailable: true,
};

const includeMembershipCards = (
    previousData: (GetDogForCareModel | GetPersonForCareModel | MembershipCardModel)[],
    newData: (GetDogForCareModel | GetPersonForCareModel)[],
): (GetDogForCareModel | GetPersonForCareModel | MembershipCardModel)[] => {
    const previousDataLength = previousData?.length || 0;
    const currentDataLength = newData.length || 0;
    let totalLength: number = previousDataLength + currentDataLength;
    const searchData: (GetDogForCareModel | GetPersonForCareModel | MembershipCardModel)[] = (previousData || []).concat(newData);
    const membershipCardInterval: number = 8;
    let membershipCardIndex: number = 2;

    if (previousDataLength > 2) {
        const lastMembershipCardIndex = (previousDataLength - 1) -
            previousData.reverse().findIndex((e: (GetDogForCareModel | GetPersonForCareModel | MembershipCardModel)) =>
                e.type === DISPLAY_CARD_TYPE.MEMBERSHIP);
        membershipCardIndex = lastMembershipCardIndex + membershipCardInterval;
    }

    for (let i = previousDataLength; i < totalLength; i++) {
        if (i === membershipCardIndex) {
            searchData.splice(membershipCardIndex, 0, { type: DISPLAY_CARD_TYPE.MEMBERSHIP });
            totalLength = searchData.length;
            membershipCardIndex = membershipCardIndex + membershipCardInterval;
        }
    }

    return searchData;
}

@State<CareStateModel>({
    name: 'care',
    defaults: defaultState
})
@Injectable()
export class CareState {

    @Selector()
    public static loading(state: CareStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static error(state: CareStateModel): any {
        return state.error;
    }

    @Selector()
    public static activeTab(state: CareStateModel): number {
        return state.activeTab;
    }

    @Selector()
    public static isDogAdd(state: CareStateModel): boolean | undefined {
        return state.isDogAdd;
    }

    @Selector()
    public static currentStep(state: CareStateModel): number {
        return state.currentStep;
    }

    @Selector()
    public static dogDetail(state: CareStateModel): GetDogForCareModel {
        return state.dogDetail as GetDogForCareModel;
    }

    @Selector()
    public static mainTabIndex(state: CareStateModel): number {
        return state.mainTabIndex;
    }

    @Selector()
    public static personData(state: CareStateModel): GetPersonForCareModel {
        return state.personData as GetPersonForCareModel;
    }

    @Selector()
    public static allPublications(state: CareStateModel): Array<GetDogForCareModel | GetPersonForCareModel> | undefined {
        return state.allPublications;
    }

    @Selector()
    public static searchData(state: CareStateModel): Array<GetDogForCareModel | GetPersonForCareModel | MembershipCardModel> | undefined {
        return state.searchData;
    }

    @Selector()
    public static isMoreData(state: CareStateModel): boolean {
        return state.isMoreData;
    }

    @Selector()
    public static skipIndex(state: CareStateModel): number {
        return state.skipIndex;
    }

    @Selector()
    public static dogFilterOption(state: CareStateModel): DogFilterModel {
        return state.dogFilterOption as DogFilterModel;
    }

    @Selector()
    public static personFilterOption(state: CareStateModel): PersonFilterModel {
        return state.personFilterOption as PersonFilterModel;
    }

    @Selector()
    public static searchTab(state: CareStateModel): string {
        return state.searchTab;
    }

    @Selector()
    public static searchDataForm(state: CareStateModel): SearchFormModel | undefined {
        return state.searchDataForm;
    }

    @Selector()
    public static outsideRadiusAvailable(state: CareStateModel): boolean {
        return state.outsideRadiusAvailable;
    }

    @Selector()
    public static searchInsideData(state: CareStateModel): Array<GetDogForCareModel | GetPersonForCareModel> | undefined {
        return state.searchInsideData;
    }

    @Selector()
    public static searchOutSideData(state: CareStateModel): Array<GetDogForCareModel | GetPersonForCareModel> | undefined {
        return state.searchOutSideData;
    }

    @Selector()
    public static insideCount(state: CareStateModel): number {
        return state.insideCount;
    }

    @Selector()
    public static totalCount(state: CareStateModel): number {
        return state.totalCount;
    }

    @Selector()
    public static insideRegion(state: CareStateModel): number {
        return state.insideRegion;
    }

    @Selector()
    public static partialCount(state: CareStateModel): number {
        return state.partialCount;
    }

    @Selector()
    public static isAddressAvailable(state: CareStateModel): boolean {
        return state.isAddressAvailable;
    }

    constructor(
        private careService: CareService,
        private toastr: ToastrNotificationService,
        private store : Store,
        private authService: AuthService,
    ) { }

    @Action(SetActiveTabIndex)
    setDogId(ctx: StateContext<CareStateModel>, action: SetActiveTabIndex): any {
        ctx.patchState({ activeTab: action.model });

    }

    @Action(SetMembershipCards)
    setIxncludeMembershipCards(ctx: StateContext<CareStateModel>, action: SetMembershipCards): any {
        ctx.patchState({ includeMembershipCards: action.model });
    }

    @Action(SetPersonOrDogForm)
    setPersonOrDogForm(ctx: StateContext<CareStateModel>, action: SetPersonOrDogForm): any {
        ctx.patchState({
            isDogAdd: action.model
        });
    }

    @Action(SetCurrentStep)
    setCurrentStep(ctx: StateContext<CareStateModel>, action: SetCurrentStep): any {
        ctx.patchState({ currentStep: action.model });
    }

    @Action(SetFilterOptions)
    setFilterOptions(ctx: StateContext<CareStateModel>, action: SetFilterOptions): any {
        if (action.model.type === 'dog') {
            ctx.patchState({
                dogFilterOption: action.model.filter as DogFilterModel,
                personFilterOption: undefined
            });
        } else {
            ctx.patchState({
                personFilterOption: action.model.filter as PersonFilterModel,
                dogFilterOption: undefined
            });
        }
    }

    @Action(SetSearchDataForm)
    setSearchDataForm(ctx: StateContext<CareStateModel>, action: SetSearchDataForm): any {
        ctx.patchState({
            searchDataForm: action.model
        });
    }

    @Action(SetSearchTab)
    setSearchTab(ctx: StateContext<CareStateModel>, action: SetSearchTab): any {
        ctx.patchState({
            searchTab: action.model
        });
    }

    @Action(SetSkipIndex)
    setSkipIndex(ctx: StateContext<CareStateModel>, action: SetSkipIndex): any {
        ctx.patchState({
            skipIndex: action.model,
            searchData: undefined,
            searchInsideData: undefined,
            searchOutSideData: undefined,
            totalFetchedRecords: 0,
        });
    }

    @Action(SearchPersonOrDog)
    searchPersonOrDog(ctx: StateContext<CareStateModel>, action: SearchPersonOrDog): any {
        ctx.patchState({ loading: true });
        const { searchDataForm, searchTab, skipIndex } = ctx.getState();
        const dogFilterOption = ctx.getState().dogFilterOption as any;
        const personFilterOption = ctx.getState().personFilterOption as any;
        const isAuth = this.authService.isAuthenticated();

        if (action.model === 'dog') {
            let qs = '';
            if (searchTab === 'general') {
                if (dogFilterOption) {
                    qs = Object.keys(dogFilterOption).map(((mK: string) => {
                        return Object.keys(dogFilterOption[mK]).map(sK => {
                            if (dogFilterOption[mK][sK]) {
                                return `${mK}[${sK}]=${dogFilterOption[mK][sK]}`;
                            } else {
                                return '';
                            }
                        }).filter(rSK => rSK).join('&');
                    })).filter(rMK => rMK).join('&');
                }
            }
            qs = qs.concat(`${qs ? '&' : ''}tab=${searchTab}&skip=${skipIndex}`);

            return this.careService.searchPersonOrDog({ qs, searchDataForm: searchDataForm as SearchFormModel })
                .pipe(
                    tap((response: ResponseBeanModel) => {
                        if (response.success) {
                            const data = [...response.data.result] as GetDogForCareModel[];
                            const filterInsideData: GetDogForCareModel[] = [];
                            const filterOutsideData: GetDogForCareModel[] = [];
                            data.forEach((d: GetDogForCareModel) => {
                                if (d.insideRadius) {
                                    filterInsideData.push(d);
                                } else {
                                    filterOutsideData.push(d);
                                }
                            });

                            const { totalFetchedRecords, searchData, searchInsideData, searchOutSideData } = ctx.getState();

                            // totalFetchedRecords is used so the we use SKIP membership profiles while fetching new data
                            const totalFetchedRecordsUpdated = totalFetchedRecords ? totalFetchedRecords + data.length : data.length;
                            const previousData = searchData ? [...searchData] : [];
                            const previousSearchInsideData = searchInsideData;
                            const previousSearchOutSideData = searchOutSideData;
                            
                            if (isAuth) this.store.dispatch(new LoadUserAction());
                            const mergedData = (!isAuth) ? includeMembershipCards(previousData, data) : data;

                            ctx.patchState({
                                totalFetchedRecords: totalFetchedRecordsUpdated,
                                searchData: mergedData,
                                searchInsideData: previousSearchInsideData ?
                                    previousSearchInsideData.concat(filterInsideData) : filterInsideData,
                                searchOutSideData: previousSearchOutSideData ?
                                    previousSearchOutSideData.concat(filterOutsideData) : filterOutsideData,
                                isMoreData: response?.data?.moreDataAvailable,
                                outsideRadiusAvailable: response?.data?.outsideRadiusAvailable,
                                skipIndex: totalFetchedRecordsUpdated,
                                totalCount: response?.data?.totalCount,
                                insideCount: response?.data?.insideCount,
                                insideRegion: response?.data?.insideRegion,
                                partialCount: response?.data?.partialCount,
                                error: undefined,
                                loading: false,
                                isAddressAvailable: (data.length && 'distance' in data[0]) ? true : false
                            });
                        }
                    }),
                    catchError((err) => {
                        ctx.patchState({
                            error: err,
                        });
                        return throwError(err);
                    }),
                    finalize(() => {
                        // ctx.patchState({ loading: false });
                    })
                );
        } else {
            let qs = '';
            if (searchTab === 'general') {
                if (personFilterOption) {
                    qs = Object.keys(personFilterOption).map(((mK: string) => {
                        return Object.keys(personFilterOption[mK]).map(sK => {
                            if (personFilterOption[mK][sK]) {
                                return `${mK}[${sK}]=${personFilterOption[mK][sK]}`;
                            } else {
                                return '';
                            }
                        }).filter(rSK => rSK).join('&');
                    })).filter(rMK => rMK).join('&');
                }
            }
            qs = qs.concat(`${qs ? '&' : ''}tab=${searchTab}&skip=${skipIndex}`);
            // console.log('244 personfilteroption', personFilterOption);
            // console.log('245 qs', qs);
            return this.careService.searchPersonOrDog({ qs, searchDataForm: searchDataForm as SearchFormModel })
                .pipe(
                    tap((response: ResponseBeanModel) => {
                        if (response.success) {
                            const data = [...response.data.result] as GetPersonForCareModel[];
                            const filterInsideData: GetPersonForCareModel[] = [];
                            const filterOutsideData: GetPersonForCareModel[] = [];
                            data.forEach((d: GetPersonForCareModel) => {
                                if (d.insideRadius) {
                                    filterInsideData.push(d);
                                } else {
                                    filterOutsideData.push(d);
                                }
                            });

                            const { totalFetchedRecords, searchData, searchInsideData, searchOutSideData } = ctx.getState();

                            // totalFetchedRecords is used so the we use SKIP membership profiles while fetching new data
                            const totalFetchedRecordsUpdated = totalFetchedRecords ? totalFetchedRecords + data.length : data.length;
                            const previousData = searchData ? [...searchData] : [];
                            const previousSearchInsideData = searchInsideData;
                            const previousSearchOutSideData = searchOutSideData;
                            
                            if (isAuth) this.store.dispatch(new LoadUserAction());
                            const mergedData = (!isAuth) ? includeMembershipCards(previousData, data) : data;

                            ctx.patchState({
                                totalFetchedRecords: totalFetchedRecordsUpdated,
                                searchData: [...previousData, ...mergedData],
                                searchInsideData: previousSearchInsideData ?
                                    previousSearchInsideData.concat(filterInsideData) : filterInsideData,
                                searchOutSideData: previousSearchOutSideData ?
                                    previousSearchOutSideData.concat(filterOutsideData) : filterOutsideData,
                                isMoreData: response?.data?.moreDataAvailable,
                                outsideRadiusAvailable: response?.data?.outsideRadiusAvailable,
                                skipIndex: totalFetchedRecordsUpdated,
                                totalCount: response?.data?.totalCount,
                                insideCount: response?.data?.insideCount,
                                insideRegion: response?.data?.insideRegion,
                                partialCount: response?.data?.partialCount,
                                error: undefined,
                                loading: false,
                                isAddressAvailable: (data.length && 'distance' in data[0]) ? true : false
                            });
                        }
                    }),
                    catchError((err) => {
                        ctx.patchState({
                            error: err,
                        });
                        return throwError(err);
                    }),
                    finalize(() => {
                        ctx.patchState({ loading: false });
                    })
                );
        }
    }
    // @Action(SetAllNullUserAction)
    // setNull(ctx: StateContext<CareStateModel>, action: SetAllNullUserAction): any {
    //     ctx.patchState(defaultState);
    // }

    @Action(AddPersonForCare)
    addPersonForCare(ctx: StateContext<CareStateModel>, action: AddPersonForCare): any {
        ctx.patchState({ loading: true });
        return this.careService.addPersonForCare(action.model as AddPersonForCareModel)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const data = response.data as GetPersonForCareModel;
                        ctx.patchState({
                            personData: data,
                            dogDetail: undefined,
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', err.error.message as string);
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(AddDogForCare)
    addDogForCare(ctx: StateContext<CareStateModel>, action: AddDogForCare): any {
        ctx.patchState({ loading: true });
        return this.careService.addDogForCare(action.model as AddDogForCareModel)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            dogDetail: response.data as GetDogForCareModel,
                            personData: undefined,
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', err.error.message as string);
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(GetSinglePublication)
    getSinglePublication(ctx: StateContext<CareStateModel>, action: GetSinglePublication): any {
        ctx.patchState({ loading: true });
        return this.careService.getSinglePublication(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        if (action.model.type === 'person') {
                            ctx.patchState({
                                personData: response.data as GetPersonForCareModel,
                                dogDetail: undefined,
                                error: undefined,
                                loading: false,
                            });
                        } else {
                            ctx.patchState({
                                dogDetail: response.data as GetDogForCareModel,
                                personData: undefined,
                                error: undefined,
                                loading: false,
                            });
                        }
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(CareImageChange)
    editImage(ctx: StateContext<CareStateModel>, action: AddDogForCare): any {
        ctx.patchState({ loading: true });
        return this.careService.editCareImage(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const allPublications = ctx.getState().allPublications?.map((a: any) => ({ ...a }));
                        if (action.model.type === 'dog') {
                            const dogDetail = ctx.getState().dogDetail as GetDogForCareModel;
                            const dogDetailOther = {
                                ...dogDetail,
                                image: response.data
                            };

                            if (allPublications) {
                                allPublications.map((a: GetDogForCareModel | GetPersonForCareModel) => {
                                    if (a.type === 'dog' && a._id === dogDetail._id) {
                                        a.image = response.data;
                                    }
                                    return a;
                                });
                            }
                            ctx.patchState({
                                allPublications,
                                dogDetail: dogDetailOther as GetDogForCareModel,
                                error: undefined,
                                loading: false,
                            });
                        } else {
                            const person = ctx.getState().personData as GetPersonForCareModel;
                            const personOther = {
                                ...person,
                                image: response.data
                            };

                            if (allPublications) {
                                allPublications.map((a: GetDogForCareModel | GetPersonForCareModel) => {
                                    if (a.type === 'person' && a._id === person._id) {
                                        a.image = response.data;
                                    }
                                    return a;
                                });
                            }
                            ctx.patchState({
                                allPublications,
                                personData: personOther as GetPersonForCareModel,
                                error: undefined,
                                loading: false,
                            });
                        }
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', err.error.message as string);
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(CareImageDelete)
    deleteImage(ctx: StateContext<CareStateModel>, action: AddDogForCare): any {
        ctx.patchState({ loading: true });
        return this.careService.deleteCareImage(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const allPublications = ctx.getState().allPublications?.map((a: any) => ({ ...a }));
                        if (action.model.type === 'dog') {
                            const dogDetail = ctx.getState().dogDetail as GetDogForCareModel;
                            const dogDetailOther = {
                                ...dogDetail,
                                image: ''
                            };

                            if (allPublications) {
                                allPublications.map((a: GetDogForCareModel | GetPersonForCareModel) => {
                                    if (a.type === 'dog' && a._id === dogDetail._id) {
                                        a.image = response.data;
                                    }
                                    return a;
                                });
                            }

                            ctx.patchState({
                                allPublications,
                                dogDetail: dogDetailOther as GetDogForCareModel,
                                error: undefined,
                                loading: false,
                            });
                        } else {
                            const person = ctx.getState().personData as GetPersonForCareModel;
                            const personOther = {
                                ...person,
                                image: ''
                            };

                            if (allPublications) {
                                allPublications.map((a: GetDogForCareModel | GetPersonForCareModel) => {
                                    if (a.type === 'person' && a._id === person._id) {
                                        a.image = response.data;
                                    }
                                    return a;
                                });
                            }
                            ctx.patchState({
                                allPublications,
                                personData: personOther as GetPersonForCareModel,
                                error: undefined,
                                loading: false,
                            });
                        }
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SetMainTabIndex)
    setMainTabIndex(ctx: StateContext<CareStateModel>, action: SetMainTabIndex): any {
        ctx.patchState({ mainTabIndex: action.model });
    }

    @Action(GetAllMyPublications)
    getAllMyPublications(ctx: StateContext<CareStateModel>, action: GetAllMyPublications): any {
        ctx.patchState({ loading: true });
        return this.careService.getAllMyPublications()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            allPublications: response.data,
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(DeleteMyPublication)
    deleteMyPublication(ctx: StateContext<CareStateModel>, action: DeleteMyPublication): any {
        ctx.patchState({ loading: true });
        return this.careService.deleteMyPublication(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SetNullDataAction)
    setNullDataAction(ctx: StateContext<CareStateModel>, action: SetNullDataAction): any {
        ctx.patchState({
            personData: undefined,
            dogDetail: undefined
        });
    }

    @Action(EditDogForCare)
    editDogForCare(ctx: StateContext<CareStateModel>, action: EditDogForCare): any {
        ctx.patchState({ loading: true });
        return this.careService.editPersonOrDogForCare(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const data = response.data as GetDogForCareModel;
                        ctx.patchState({
                            dogDetail: data,
                            personData: undefined,
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(EditPersonForCare)
    editPersonForCare(ctx: StateContext<CareStateModel>, action: EditPersonForCare): any {
        ctx.patchState({ loading: true });
        return this.careService.editPersonOrDogForCare(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const data = response.data as GetPersonForCareModel;
                        ctx.patchState({
                            personData: data,
                            dogDetail: undefined,
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    this.toastr.showError('', err.error.message as string);
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(AddToFavourite)
    addToFavourite(ctx: StateContext<CareStateModel>, action: AddToFavourite): any {
        ctx.patchState({ loading: true });
        return this.careService.addToFavourite({ id: action.model.id, type: action.model.type, add: action.model.add })
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const tab = ctx.getState().searchTab;
                        const { searchInsideData, searchOutSideData } = ctx.getState();
                        if (tab !== 'favourite') {
                            if (typeof action.model.insideData !== undefined) {
                                if (action.model.insideData) {
                                    const deepCopyInsideData = cloneDeep(searchInsideData) as Array<GetDogForCareModel | GetPersonForCareModel>;
                                    deepCopyInsideData[action.model.index as number].favourite = action.model.add;
                                    ctx.patchState({
                                        error: undefined,
                                        loading: false,
                                        searchInsideData: deepCopyInsideData
                                    });
                                } else {
                                    const deepCopyOutsideData = cloneDeep(searchOutSideData) as Array<GetDogForCareModel | GetPersonForCareModel>;
                                    deepCopyOutsideData[action.model.index as number].favourite = action.model.add;
                                    ctx.patchState({
                                        error: undefined,
                                        loading: false,
                                        searchOutSideData: deepCopyOutsideData
                                    });
                                }
                            }
                            ctx.patchState({
                                error: undefined,
                                loading: false,
                            });
                        } else {
                            ctx.patchState({
                                error: undefined,
                                loading: false,
                                searchInsideData: searchInsideData?.filter(item => item._id !== action.model.id)
                            });
                        }
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(PublishMyPublication)
    publishMyPublication(ctx: StateContext<CareStateModel>, action: PublishMyPublication): any {
        ctx.patchState({ loading: true });
        return this.careService.publishMyPublication(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(ActivationChangePublication)
    activationChangePublication(ctx: StateContext<CareStateModel>, action: ActivationChangePublication): any {
        ctx.patchState({ loading: true });
        return this.careService.activationChangePublication(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            // loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    this.toastr.showError(err?.error?.message, '');
                    return throwError(err?.error);
                }),
                finalize(() => {
                    // ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SetLoaderState)
    setLoaderState(ctx: StateContext<CareStateModel>, action: SetLoaderState): any {
        ctx.patchState({
            loading: action.model
        });
    }

    @Action(ScrollAction)
    scrollAction(ctx: StateContext<CareStateModel>, action: ScrollAction): any {
        // console.log('855');

        const { id, desktop, mobile } = action.model;
        const ele = document.getElementById(id) as HTMLElement;
        const yOffset = window.innerWidth > 767 ? desktop : mobile;
        const y = ele.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    @Action(FeedbackFormSubmit)
    feedbackFormSubmit(ctx: StateContext<CareStateModel>, action: FeedbackFormSubmit): any {
        ctx.patchState({ loading: true });
        return this.careService.feedbackFormSubmit(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }
}
