import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UserModel } from '@core/models/user.model';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { LastAdDeleteConfPopupComponent } from 'app/profile/shared/components/last-ad-delete-conf-popup/last-ad-delete-conf-popup.component';
import { InfoPopupComponent } from 'app/profile/shared/info-popup/info-popup.component';
import { AddToFavouriteForAllAds, ChangeKeyValue, LoadUserAction } from 'app/profile/shared/store/user/user.actions';
import { UserState } from 'app/profile/shared/store/user/user.state';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { FeedbackFormModel } from '../../shared/models/feedback-form.model';
import { GetDogForCareModel } from '../../shared/models/get-dog-for-care.model';
import { GetPersonForCareModel } from '../../shared/models/get-person-for-care.model';
import { ActivationChangePublication, AddToFavourite, DeleteMyPublication, FeedbackFormSubmit, GetAllMyPublications, GetSinglePublication, SetMainTabIndex, SetPersonOrDogForm } from '../../shared/store/care.actions';
import { CareState } from '../../shared/store/care.state';
import { ViewAllEmergencyDogsAction } from '../../shared/store/emergency_dog/emergency.action';
import { DeleteParticipantPopupComponent } from '../delete-participant-popup/delete-participant-popup.component';
import { DogPreviewDialogComponent } from '../dog-preview-dialog/dog-preview-dialog.component';
import { FeedbackFormComponent } from '../feedback-form/feedback-form.component';
import { GetSubscriptionPlanByType, GetUserCurrentProductPaymentsData } from 'app/profile/shared/store/product/product.action';
import { ProductState } from 'app/profile/shared/store/product/product.state';
import { SubscriptionParentComponent } from 'app/profile/shared/components/subscription/subscription-parent/subscription-parent.component';
import { APP_ERROR_CODE } from 'app/contants';
import { getDogAgeState } from '@utils';

@Component({
  selector: 'app-dog-person-sharing-dog-card',
  templateUrl: './dog-card.component.html',
  styleUrls: ['./dog-card.component.scss']
})
export class DogCardgComponent implements OnInit {

  @Input() data!: any;
  @Input() participant!: boolean;
  @Input() index!: number;
  @Input() startIndex!: number;
  @Input() insideData!: boolean;
  @Input() openedFrom: string = '';
  @Input() thirdTab!: boolean;

  careTimingList: any[] = [];
  showLastLine: boolean = false;
  currentUser: any;
  @Select(UserState.user) user$ !: Observable<UserModel>;
  showToggle: Boolean = true;
  item!: any;
  constructor(
    private store: Store,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private toastr: ToastrNotificationService
  ) { }

  ngOnInit(): void {
    this.item = this.setDogData({ ...this.data });
    const { careTiming } = this.item;
    this.careTimingList = this.customGroupBy(careTiming);

    this.item['showLastLine'] = (this.careTimingList.length > 0) ? true : false;
    
    this.getUserData();
  }

  customGroupBy(myObj: { [key: string]: boolean }): any {
    return Object.keys(myObj).filter((key) => myObj[key]);
  }

  /**
   * get user detail
   */
  getUserData() {
    this.user$.subscribe((user: UserModel) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  setDogData(d: any): any {
    const dogNatureExist: string[] = [];
    Object.keys(d.dogNature).forEach((s: string) => {
      if (d.dogNature[s]) {
        dogNatureExist.push(s);
      }
    });
    d.dogNatureExist = dogNatureExist;

    const dateOfBirth = d?.dog?.birthDay?.split('.').join('/');
    const dogYears = moment().diff(moment(d?.dog?.birthDay?.split('.').join('/'), 'DD/MM/YYYY'), 'years');
    const dogMonths = moment().diff(moment(d?.dog?.birthDay?.split('.').join('/'), 'DD/MM/YYYY'), 'months');

    d.dogType = getDogAgeState(dateOfBirth, d?.dog?.size);
    d.dogYears = dogYears;
    d.dogMonths = dogMonths;

    d.imageAvailable = !!(d?.image || d?.dog?.profilePhoto);
    return d;
  }

  /**
   * check is active add is last or not and manage functions for delete ad
   */
  manageDeletePublicationPopup(id: string, type: string, active: boolean) {
    let feedback = '';
    const feedbackForm = this.feedbackForm();
    feedbackForm.afterClosed().subscribe(
      (res) => {
        feedback = res;
        if (feedback) {
          if (this.currentUser?.totalActiveAds === 1) {
            this.dialog.open(LastAdDeleteConfPopupComponent, {
              panelClass: 'delete-active-ad-dialog',
              disableClose: true,
              data: {
              }
            }).afterClosed()
              .subscribe(response => {
                if (response?.action === 'delete') {
                  this.deletePublication(id, type, active, true, feedback);
                } else if (response?.action === 'deactive') {
                  this.activeDeactiveAd(id, false, '', true, feedback);
                }
              }
              );
          } else {
            this.deletePublication(id, type, active, false, feedback);
          }
        }
      }
    );
  }


  /**
 * update user data after purchase credit
 */
  updateUserData() {
    this.store.dispatch(new LoadUserAction());
  }

  deletePublication(id: string, type: string, active: boolean, updateProfile: boolean, feedback: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: 'PAGE.DOG_CARE.DOG_SHARING.SHARED.DELETE_AD_POPUP.TITLE',
      message: 'PAGE.DOG_CARE.DOG_SHARING.SHARED.DELETE_AD_POPUP.TEXT',
      secondarySubmit: active ? 'PAGE.DOG_CARE.DOG_SHARING.SHARED.DELETE_AD_POPUP.SECONDARY_SUBMIT' : '',
      submit: 'PAGE.DOG_CARE.DOG_SHARING.SHARED.DELETE_AD_POPUP.SUBMIT'
    };
    const dialogRef = this.dialog.open(DeleteParticipantPopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.reason) {
          this.store.dispatch(new FeedbackFormSubmit(this.createObj(id, 'dogCare', 'delete', feedback))).subscribe
            (() => {
              this.store.dispatch(new DeleteMyPublication({ id, type })).subscribe(
                () => {
                  if (updateProfile) {
                    this.updateUserData()
                  }
                  this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.DELETE_AD_POPUP.SUCCESS_MESSAGE').subscribe((res: string) => {
                    this.toastr.showSuccess(res, '');

                  });
                  this.store.dispatch(new GetAllMyPublications()).subscribe(
                    () => {
                      const data = this.store.selectSnapshot(CareState.allPublications) as Array<GetDogForCareModel | GetPersonForCareModel>;
                      if (!data || !data.length) {
                        this.store.dispatch(new ChangeKeyValue({ key: 'isAdAvailable', value: false }));
                      }
                    },
                    () => { }
                  );
                },
                () => {
                }
              );
            },
              () => { });
        } else {
          this.activateChange(id, { checked: false }, false, feedback);
        }
      }
    });
  }

  onDogPreviewDialog(id: string): void {
    this.store.dispatch(new GetSinglePublication({
      id,
      type: 'dog'
    })).subscribe(
      () => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.closeOnNavigation = false;
        dialogConfig.data = {
          afterAdd: false,
          participant: this.participant,
          index: this.index + this.startIndex,
          openedFrom: this.openedFrom,
          thirdTab: this.thirdTab
        };
        this.dialog.open(DogPreviewDialogComponent, dialogConfig);
      },
      () => { }
    );
  }

  onDogEdit(id: string): void {
    this.store.dispatch(new GetSinglePublication({
      id,
      type: 'dog'
    })).subscribe(
      () => {
        this.store.dispatch(new SetPersonOrDogForm(true));
        this.store.dispatch(new SetMainTabIndex(0));
      },
      () => { }
    );
  }

  favouriteChange(event: MouseEvent, id: string, status: boolean): void {
    event.stopPropagation();
    if (this.openedFrom === 'adds') {
      this.store.dispatch(new AddToFavouriteForAllAds({ id, type: 'dog', add: !status })).subscribe(
        () => {
        },
        () => {
        }
      )
    } else {
      this.store.dispatch(new AddToFavourite({ id, type: 'dog', add: !status, insideData: this.insideData, index: this.index })).subscribe(
        () => {
        },
        () => {
        });
    }
  }

  activateChange(id: string, value: any, item: any, feedback: string): void {
    
    // If profile is already inactive, we do not need to again inactivate it
    if (!value.checked && !item.active) {
      return;
    }

    // Verify if image is available
    if (!this.item?.imageAvailable && value.checked) {
      this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_PREVIEW_DIALOG.UPLOAD_PHOTO_ERROR').subscribe((res: string) => {
        this.toastr.showError(res, '');
        setTimeout(() => {
          value.source.toggle(false);
          value.checked = false;
        }, 300)
      });
      return;
    }

    if (feedback) {
      this.afterActivateChangeCallback(id, value, item, feedback);
    } else {
      if (!value.checked) {
        const feedbackForm = this.feedbackForm();
        feedbackForm.afterClosed().subscribe(
          (res) => {
            if (res) {
              this.afterActivateChangeCallback(id, value, item, res);
            } else {
              value.source.toggle(true);
            }
          }
        );
      } else {
        this.afterActivateChangeCallback(id, value, item, '');
      }
    }

    // this.activeDeactiveAd(id, value, item, true); // added for remove payment flow
  }

  /**
   * manage active or deactive add
   * @param id 
   * @param value 
   * @param item 
   */
  activeDeactiveAd(id: string, value: any, item: any, updateProfile: boolean = false, feedback: string) {
    this.store.dispatch(new ActivationChangePublication({ id, type: 'dog', value: value?.checked })).subscribe(
      () => {

        if (updateProfile) {
          this.updateUserData()
        }
        this.store.dispatch(new GetAllMyPublications()).subscribe(
          () => {
            if (value.checked) {
              this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.ACTIVE_SUCCESS').subscribe((res: string) => {
                this.toastr.showSuccess(res, '');
              });
            } else {
              this.store.dispatch(new FeedbackFormSubmit(this.createObj(id, 'dogCare', 'deactive', feedback)));
              this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.INACTIVE_SUCCESS').subscribe((res: string) => {
                this.toastr.showSuccess(res, '');
              });
            }
            // on emergencydog active and inactive reflect to emergency section
            this.store.dispatch(new ViewAllEmergencyDogsAction(true));
          },
          () => {
          }
        );
      },
      (error) => {

        let errorCode = undefined;

        if (error?.data?.errorCode) errorCode = error?.data?.errorCode;
        else if (error?.error?.data?.errorCode) errorCode = error?.error?.data?.errorCode;

        if (errorCode === APP_ERROR_CODE.PAYMENT_INCOMPLETE) {
          // if error is payment related show payment modal
          this.store.dispatch(new GetUserCurrentProductPaymentsData()).subscribe(
            async () => {
              const publicationId = id;
              const activePayments = this.store.selectSnapshot(ProductState.getActivePayments);
              const activePaymentsFiltered = activePayments.filter((payment)=> payment?.uniqueId === publicationId);
              const existingSelectedIds = activePaymentsFiltered.map((payment) => payment?.product?._id);

              await this.getDogSharingSubscriptionPlan();
              const dogSharingSubscriptionPlan: any = this.store.selectSnapshot(ProductState.getSubscriptionPlanByType);

              let keyValMatchObj: any = {
                anonymousAd: 'anonymousAd',
                emergencyCare: 'emergencyCare',
                dogNotOnHome: 'lpspecificaddisable',
                displayLocation: 'withoutResidence'
              }
              let selectedIds = [];
              // let existingSelectedIds: Array<any> = [];
              for (const key of Object.keys(keyValMatchObj)) {
                if (this.item[key] === true) { // find on edit new selected plan ids
                  let index = dogSharingSubscriptionPlan.findIndex((plan: any) => plan?.subType === keyValMatchObj[key]);
                  selectedIds.push(dogSharingSubscriptionPlan[index]?._id);
                }
              }

              this.dialog.open(SubscriptionParentComponent, {
                panelClass: 'subscription-parent-dialog',
                disableClose: true,
                data: {
                  type: 'dogCare',
                  subType: '',
                  mode: 'edit',
                  selectedIds: selectedIds,
                  existingIds: existingSelectedIds,
                  dogOrPersonId: publicationId
                }
              }).afterClosed()
                .subscribe(response => {

                  if (response?.resp === 'success') {
                    this.store.dispatch(new ActivationChangePublication({ id, type: 'dog', value: value?.checked })).subscribe(
                      () => {
                        this.store.dispatch(new GetAllMyPublications()).subscribe(
                          () => {
                            if (value.checked) {
                              this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.ACTIVE_SUCCESS').subscribe((res: string) => {
                                this.toastr.showSuccess(res, '');
                              });
                            } else {
                              this.store.dispatch(new FeedbackFormSubmit(this.createObj(id, 'dogCare', 'deactive', feedback)));
                              this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.INACTIVE_SUCCESS').subscribe((res: string) => {
                                this.toastr.showSuccess(res, '');
                              });
                            }
                            this.store.dispatch(new ViewAllEmergencyDogsAction(true));// on emergencydog active and inactive reflect to emergency section
                          },
                          () => {
                          }
                        );
                      }
                    );
                  }
                });
            },
            () => {
          });
        }

        value.source.toggle(true);

    });
  }

  getDogSharingSubscriptionPlan() {
    return new Promise((resolve, reject) => {
      this.store.dispatch(new GetSubscriptionPlanByType({ type: 'dogCare', subType: '' })).subscribe(
        () => {
          resolve('');
        },
        () => {
          reject('');
        }
      );
    })
  }

  openWarningPopup(id: string, value: any, item: any, feedback: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      dogPaymentSubscriptionKeys: { anonymousAd: this.item.anonymousAd, emergencyCare: this.item.emergencyCare, displayLocation: this.item.displayLocation, dogNotOnHome: this.item.dogNotOnHome },
    };
    this.dialog.open(InfoPopupComponent, dialogConfig).afterClosed()
      .subscribe(res => {
        if (!res) {
          value.source.toggle(true);
          return;
        }
        this.store.dispatch(new ActivationChangePublication({ id, type: 'dog', value: value?.checked })).subscribe(
          () => {
            this.updateUserData();
            this.store.dispatch(new GetAllMyPublications()).subscribe(
              () => {
                if (value?.checked) {
                  this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.ACTIVE_SUCCESS').subscribe((res: string) => {
                    this.toastr.showSuccess(res, '');
                  });
                } else {
                  this.store.dispatch(new FeedbackFormSubmit(this.createObj(id, 'dogCare', 'deactive', feedback)));
                  this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.INACTIVE_SUCCESS').subscribe((res: string) => {
                    this.toastr.showSuccess(res, '');
                  });
                }
                this.store.dispatch(new ViewAllEmergencyDogsAction(true));// on emergencydog active and inactive reflect to emergency section
              },
              () => {
              }
            );
          },
          () => {
          });
      })
  }

  feedbackForm(): MatDialogRef<FeedbackFormComponent> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    return this.dialog.open(FeedbackFormComponent, dialogConfig);
  }

  afterActivateChangeCallback(id: string, value: any, item: any, feedback: string): void {
    if (value.checked === false) { // removed payment flow
      if (item.anonymousAd == false && item.emergencyCare == false && item.dogNotOnHome == false && item.displayLocation == false) {
        this.activeDeactiveAd(id, value, item, true, feedback);

      } else {
        this.openWarningPopup(id, value, item, feedback);
      }
    } else {
      if (item?.dog?.ripDate == null) {
        this.activeDeactiveAd(id, value, item, true, feedback);
      } else {
        this.showToggle = false;
        this.translateService.get('COMMON.RIP_DOG_EDIT_ACTIVATE_MSG').subscribe((res: string) => {
          this.toastr.showError(res, '');
        });
        // value.source.toggle(false);
        // value.checked = false;
        let dt = this.setDogData({ ...this.data });
        this.item = JSON.parse(JSON.stringify(dt));
        setTimeout(() => {
          this.showToggle = true;
        });
      }
    }
  }

  createObj(adId: string, type: string, action: string, feedback: string): FeedbackFormModel {
    return {
      adId, type, action, feedback
    };
  }
}

// import { Component, Input, OnInit } from '@angular/core';
// import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { UserModel } from '@core/models/user.model';
// import { ToastrNotificationService } from '@core/services/toastr-notification.service';
// import { TranslateService } from '@ngx-translate/core';
// import { Select, Store } from '@ngxs/store';
// import { LastAdDeleteConfPopupComponent } from 'app/profile/shared/components/last-ad-delete-conf-popup/last-ad-delete-conf-popup.component';
// import { InfoPopupComponent } from 'app/profile/shared/info-popup/info-popup.component';
// import { AddToFavouriteForAllAds, ChangeKeyValue, LoadUserAction } from 'app/profile/shared/store/user/user.actions';
// import { UserState } from 'app/profile/shared/store/user/user.state';
// import * as moment from 'moment';
// import { Observable } from 'rxjs';
// import { GetDogForCareModel } from '../../shared/models/get-dog-for-care.model';
// import { GetPersonForCareModel } from '../../shared/models/get-person-for-care.model';
// import { ActivationChangePublication, AddToFavourite, DeleteMyPublication, GetAllMyPublications, GetSinglePublication, SetMainTabIndex, SetPersonOrDogForm } from '../../shared/store/care.actions';
// import { CareState } from '../../shared/store/care.state';
// import { ViewAllEmergencyDogsAction } from '../../shared/store/emergency_dog/emergency.action';
// import { DeleteParticipantPopupComponent } from '../delete-participant-popup/delete-participant-popup.component';
// import { DogPreviewDialogComponent } from '../dog-preview-dialog/dog-preview-dialog.component';

// @Component({
//   selector: 'app-dog-person-sharing-dog-card',
//   templateUrl: './dog-card.component.html',
//   styleUrls: ['./dog-card.component.scss']
// })
// export class DogCardgComponent implements OnInit {

//   @Input() data!: any;
//   @Input() participant!: boolean;
//   @Input() index!: number;
//   @Input() startIndex!: number;
//   @Input() insideData!: boolean;
//   @Input() openedFrom: string = '';
//   currentUser: any;
//   @Select(UserState.user) user$ !: Observable<UserModel>;
//   showToggle: Boolean = true;
//   item!: any;
//   constructor(
//     private store: Store,
//     private dialog: MatDialog,
//     private translateService: TranslateService,
//     private toastr: ToastrNotificationService
//   ) { }

//   ngOnInit(): void {
//     this.item = this.setDogData({ ...this.data });
//     this.getUserData();
//   }

//   /**
//    * get user detail
//    */
//   getUserData() {
//     this.user$.subscribe((user: UserModel) => {
//       if (user) {
//         this.currentUser = user;
//       }
//     });
//   }

//   setDogData(d: any): any {
//     const dogNatureExist: string[] = [];
//     Object.keys(d.dogNature).forEach((s: string) => {
//       if (d.dogNature[s]) {
//         dogNatureExist.push(s);
//       }
//     });
//     d.dogNatureExist = dogNatureExist;
//     const bday = d?.dog?.birthDay?.split('.').reverse().join('/');
//     const dogYears = moment().diff(moment(d?.dog?.birthDay?.split('.').join('/'), 'DD/MM/YYYY'), 'years');
//     const dogMonths = moment().diff(moment(d?.dog?.birthDay?.split('.').join('/'), 'DD/MM/YYYY'), 'months');
//     const size = d?.dog?.size;
//     let dogType = '';
//     if (dogYears >= 0 && d?.dog?.size) {
//       if (dogYears === 0 && dogMonths < 7) {
//         dogType = 'PUPPY';
//       } else if ((dogMonths > 6 && dogYears === 0) || (dogYears < 3 && dogYears > 0)) {
//         dogType = 'JUNIOR';
//       } else if (dogYears > 2 && dogYears <= 10 && (size === 's' || size === 'xs')) {
//         dogType = 'ADULT';
//       } else if (dogYears > 2 && dogYears <= 8 && size === 'm') {
//         dogType = 'ADULT';
//       } else if (dogYears > 2 && dogYears <= 7 && size === 'l') {
//         dogType = 'ADULT';
//       } else if (dogYears > 2 && dogYears <= 5 && size === 'xl') {
//         dogType = 'ADULT';
//       } else if (dogYears > 10 && (size === 's' || size === 'xs')) {
//         dogType = 'SENIOR';
//       } else if (dogYears > 8 && size === 'm') {
//         dogType = 'SENIOR';
//       } else if (dogYears > 7 && size === 'l') {
//         dogType = 'SENIOR';
//       } else if (dogYears > 5 && size === 'xl') {
//         dogType = 'SENIOR';
//       } else {
//         dogType = 'NOT_MATCHED';
//       }
//     } else {
//       dogType = '';
//     }
//     d.dogType = dogType;
//     d.dogYears = dogYears;
//     d.dogMonths = dogMonths;
//     return d;
//   }

//   /**
//    * check is active add is last or not and manage functions for delete ad
//    */
//   manageDeletePublicationPopup(id: string, type: string, active: boolean) {
//     if (this.currentUser?.totalActiveAds === 1) {
//       this.dialog.open(LastAdDeleteConfPopupComponent, {
//         panelClass: 'delete-active-ad-dialog',
//         disableClose: true,
//         data: {

//         }
//       }).afterClosed()
//         .subscribe(response => {
//           if (response?.action === 'delete') {
//             this.deletePublication(id, type, active, true);
//           } else if (response?.action === 'deactive') {
//             this.activeDeactiveAd(id, false, '', true);
//           }
//         }
//         )
//     } else {
//       this.deletePublication(id, type, active);
//     }
//   }


//   /**
//  * update user data after purchase credit
//  */
//   updateUserData() {
//     this.store.dispatch(new LoadUserAction());
//   }

//   deletePublication(id: string, type: string, active: boolean, updateProfile: boolean = false): void {
//     const dialogConfig = new MatDialogConfig();
//     dialogConfig.autoFocus = false;
//     dialogConfig.data = {
//       title: 'PAGE.DOG_CARE.DOG_SHARING.SHARED.DELETE_AD_POPUP.TITLE',
//       message: 'PAGE.DOG_CARE.DOG_SHARING.SHARED.DELETE_AD_POPUP.TEXT',
//       secondarySubmit: active ? 'PAGE.DOG_CARE.DOG_SHARING.SHARED.DELETE_AD_POPUP.SECONDARY_SUBMIT' : '',
//       submit: 'PAGE.DOG_CARE.DOG_SHARING.SHARED.DELETE_AD_POPUP.SUBMIT'
//     };
//     const dialogRef = this.dialog.open(DeleteParticipantPopupComponent, dialogConfig);

//     dialogRef.afterClosed().subscribe(result => {
//       if (result) {
//         if (result.reason) {
//           this.store.dispatch(new DeleteMyPublication({ id, type })).subscribe(
//             () => {
//               if (updateProfile) {
//                 this.updateUserData()
//               }
//               this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.DELETE_AD_POPUP.SUCCESS_MESSAGE').subscribe((res: string) => {
//                 this.toastr.showSuccess(res, '');

//               });
//               this.store.dispatch(new GetAllMyPublications()).subscribe(
//                 () => {
//                   const data = this.store.selectSnapshot(CareState.allPublications) as Array<GetDogForCareModel | GetPersonForCareModel>;
//                   if (!data || !data.length) {
//                     this.store.dispatch(new ChangeKeyValue({ key: 'isAdAvailable', value: false }));
//                   }
//                 },
//                 () => { }
//               );
//             },
//             () => {
//             }
//           );
//         } else {
//           this.activateChange(id, { checked: false }, false);
//         }
//       }
//     });
//   }

//   onDogPreviewDialog(id: string): void {
//     this.store.dispatch(new GetSinglePublication({
//       id,
//       type: 'dog'
//     })).subscribe(
//       () => {
//         const dialogConfig = new MatDialogConfig();
//         dialogConfig.disableClose = true;
//         dialogConfig.autoFocus = false;
//         dialogConfig.closeOnNavigation = false;
//         dialogConfig.data = {
//           afterAdd: false,
//           participant: this.participant,
//           index: this.index + this.startIndex,
//           openedFrom: this.openedFrom
//         };
//         this.dialog.open(DogPreviewDialogComponent, dialogConfig);
//       },
//       () => { }
//     );
//   }

//   onDogEdit(id: string): void {
//     this.store.dispatch(new GetSinglePublication({
//       id,
//       type: 'dog'
//     })).subscribe(
//       () => {
//         this.store.dispatch(new SetPersonOrDogForm(true));
//         this.store.dispatch(new SetMainTabIndex(0));
//       },
//       () => { }
//     );
//   }

//   favouriteChange(id: string, status: boolean): void {
//     if (this.openedFrom === 'adds') {
//       this.store.dispatch(new AddToFavouriteForAllAds({ id, type: 'dog', add: !status })).subscribe(
//         () => {
//         },
//         () => {
//         }
//       )
//     } else {
//       this.store.dispatch(new AddToFavourite({ id, type: 'dog', add: !status, insideData: this.insideData, index: this.index })).subscribe(
//         () => {
//         },
//         () => {
//         });
//     }
//   }

//   activateChange(id: string, value: any, item: any): void {
//     // console.log('235', item)
//     if (value.checked === false) { // removed payment flow
//       if (item.anonymousAd == false && item.emergencyCare == false && item.dogNotOnHome == false && item.displayLocation == false) {
//         this.activeDeactiveAd(id, value, item, true);

//       } else {
//         this.openWarningPopup(id, value, item)
//       }
//     } else {
//       if (item?.dog?.ripDate == null) {
//         this.activeDeactiveAd(id, value, item, true);
//       } else {
//         this.showToggle = false;
//         this.translateService.get('COMMON.RIP_DOG_EDIT_ACTIVATE_MSG').subscribe((res: string) => {
//           this.toastr.showError(res, '');
//         });
//         // value.source.toggle(false);
//         // value.checked = false;
//         let dt = this.setDogData({ ...this.data });
//         this.item = JSON.parse(JSON.stringify(dt));
//         setTimeout(() => {
//           this.showToggle = true;
//         });
//       }
//     }

//     // this.activeDeactiveAd(id, value, item, true); // added for remove payment flow
//   }

//   /**
//    * manage active or deactive add
//    * @param id
//    * @param value
//    * @param item
//    */
//   activeDeactiveAd(id: string, value: any, item: any, updateProfile: boolean = false) {
//     this.store.dispatch(new ActivationChangePublication({ id, type: 'dog', value: value?.checked })).subscribe(
//       () => {

//         if (updateProfile) {
//           this.updateUserData()
//         }
//         this.store.dispatch(new GetAllMyPublications()).subscribe(
//           () => {
//             if (value.checked) {
//               this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.ACTIVE_SUCCESS').subscribe((res: string) => {
//                 this.toastr.showSuccess(res, '');
//               });
//             } else {
//               this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.INACTIVE_SUCCESS').subscribe((res: string) => {
//                 this.toastr.showSuccess(res, '');
//               });
//             }
//             this.store.dispatch(new ViewAllEmergencyDogsAction(true));// on emergencydog active and inactive reflect to emergency section
//           },
//           () => {
//           }
//         );
//       },
//       () => {
//         value.source.toggle(true);
//       });
//   }

//   openWarningPopup(id: string, value: any, item: any): void {
//     const dialogConfig = new MatDialogConfig();
//     dialogConfig.disableClose = true;
//     dialogConfig.autoFocus = false;
//     dialogConfig.data = {
//       dogPaymentSubscriptionKeys: { anonymousAd: this.item.anonymousAd, emergencyCare: this.item.emergencyCare, displayLocation: this.item.displayLocation, dogNotOnHome: this.item.dogNotOnHome },
//     };
//     this.dialog.open(InfoPopupComponent, dialogConfig).afterClosed()
//       .subscribe(res => {
//         if (!res) {
//           value.source.toggle(true)
//           return;
//         }
//         this.store.dispatch(new ActivationChangePublication({ id, type: 'dog', value: value?.checked })).subscribe(
//           () => {
//             this.updateUserData();
//             this.store.dispatch(new GetAllMyPublications()).subscribe(
//               () => {
//                 if (value?.checked) {
//                   this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.ACTIVE_SUCCESS').subscribe((res: string) => {
//                     this.toastr.showSuccess(res, '');
//                   });
//                 } else {
//                   this.translateService.get('PAGE.DOG_CARE.DOG_SHARING.SHARED.INACTIVE_SUCCESS').subscribe((res: string) => {
//                     this.toastr.showSuccess(res, '');
//                   });
//                 }
//                 this.store.dispatch(new ViewAllEmergencyDogsAction(true));// on emergencydog active and inactive reflect to emergency section
//               },
//               () => {
//               }
//             );
//           },
//           () => {
//           });
//       })
//   }


// }
