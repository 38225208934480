<div class="box-result" [ngClass]="{'cursor-pointer': !participant}" (click)="!participant ? onPersonPreviewDialog(item._id) : null;">
  <div class="card--img">
    <div class="img-result">
      <img class="cursor-pointer" (click)="participant ? onPersonPreviewDialog(item._id) : null;"
        [src]="item?.image ? item?.image : (item?.user?.profilePhoto ? item?.user?.profilePhoto : 'assets/img/img_person_placeholder_square.svg')"
        alt="FairDogs_Hundehalter">

      <ng-container *ngIf="!participant && item.isNewest">
        <span class="yellow-ribbon d-inline-block">{{'PAGE.DOG_CARE.DOG_SHARING.SHARED.NEW_PROFILE_RIBBON_TEXT' |
            translate}}</span>
      </ng-container>
    </div>
    <ng-container *ngIf="!participant; else activateBlock">
      <div class="fav-work">
        <img width="20" class="cursor-pointer fav-unfav-shadow" (click)="favouriteChange($event, item?._id,item?.favourite)"
          [src]="item?.favourite ? 'assets/img/fav_view.svg' : 'assets/img/un-fav_view.svg'" alt="FairDogs_FavHunde">
      </div>
    </ng-container>
    <ng-template #activateBlock>
      <div class="onoff-switch fav-work">
        <div class="d-flex align-items-center" [matTooltipPosition]="'above'"
          [matTooltip]="!item.published ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.PUBLISH_TOOLTIP' | translate) : null">
          <mat-slide-toggle [checked]="item?.active"
            (change)="activateChange(item?._id, $event, item, '')"></mat-slide-toggle>
          <div class="ml-2">
            <p class="m-0 fs-12 font-weight-medium" [ngClass]="item?.active ? 'color-green' : 'font-color-gray'">{{
              item?.active ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.ACTIVE' | translate) :
              ('PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.INACTIVE' | translate)}}</p>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="l-action-result">
      <div class="progress-bar-bottom-result px-2 py-1">
        <span class="font-weight-normal text-white fs-12">
          {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.EXPERIENCE' | translate }}
        </span>
        <mat-progress-bar mode="determinate" [value]="item.whenWithDog*5"></mat-progress-bar>
      </div>
    </div>
  </div>
  <div class="desc-result">
    <div class="d-flex justify-content-between">
      <div class="w-100">
        <h6 class="font-weight-bold fs-16 color-blue d-flex align-items-center text-nowrap">
          <ng-container *ngIf="participant; else otherUserBlock">
            <span class="cus-name-width">
              <ng-container *ngIf="user?.userName; else participantUserNameNull">
                {{ user?.userName ? user?.userName : '' }}
              </ng-container>
              <ng-template #participantUserNameNull>
                {{user.firstName + ' ' + user.lastName.charAt(0)+ '.'}}
              </ng-template>
            </span>
          </ng-container>
          <ng-template #otherUserBlock>
            <span class="cus-name-width">
              <ng-container *ngIf="item?.user?.userName; else otherUserNameNull">
                {{ item?.user?.userName ? item?.user?.userName : '' }}
              </ng-container>
              <ng-template #otherUserNameNull>
                {{item?.user?.firstName + ' ' + item?.user?.lastName.charAt(0)+ '.'}}
              </ng-template>
            </span>
          </ng-template>
          <span class="d-inline fs-14 ml-2 color-gray lh-initial text-nowrap" *ngIf="personAge">
            <ng-container [ngSwitch]="true">
              <ng-container *ngSwitchCase="personAge<14">
                {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.UNDER_AGE' | translate }} 14 {{'PAGE.YEAR' |
                translate }}
              </ng-container>
              <ng-container *ngSwitchCase="personAge>=14 && personAge<18">
                14-18 {{'PAGE.YEAR' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="personAge>=18 && personAge<30">
                18-30 {{'PAGE.YEAR' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="personAge>=30 && personAge<50">
                30-50 {{'PAGE.YEAR' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="personAge>=50 && personAge<60">
                50-60 {{'PAGE.YEAR' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="personAge>=60 && personAge<75">
                60-75 {{'PAGE.YEAR' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="personAge>=75">
                {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_PREVIEW_DIALOG.ABOVE_AGE' | translate }} 75
              </ng-container>
            </ng-container>
          </span>
        </h6>
        <p class="fs-14 color-gray mb-1"> <img src="assets/img/home-icon.svg" class="mr-1" alt="FairDogs_Hundehalter">
          <span [ngClass]="{'text-strike-through': !item.childrenExist}">
            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.CHILDREN' | translate }}
          </span>,
          <span [ngClass]="{'text-strike-through': !item.dogExist}">
            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.DOG' | translate }}
          </span>,
          <span [ngClass]="{'text-strike-through': !item.catExist}">
            {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.CAT' | translate }}
          </span>
          <ng-container *ngIf="item.gardenExist">,
            <span>
              {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.GARDEN' | translate }}
            </span>
          </ng-container>
        </p>
      </div>
    </div>
    <div class="divide-result-work col-2 px-0">
      <hr class="my-2">
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <span class="fs-14 color-gray mr-1">
          {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.HAPPY_WITH' | translate }}
        </span>
        <ul class="list-unstyled p-0 m-0 d-flex mr-1">
          <li *ngFor="let i of item.dogNumberPrefer">
            <img class="mb-1" width="20px" src="assets/img/dog-running.svg" alt="FairDogs_Hunde">
          </li>
        </ul>
      </div>
      <div>
        <span class="fs-14 color-gray">
          <ng-container *ngFor="let sizeName of item.preferedSize; let index = index">
            {{ sizeName.toUpperCase()}}<ng-container *ngIf="index!==(item.preferedSize.length-1)">,</ng-container>
          </ng-container>
        </span>
      </div>
    </div>
    <!-- <div class="divide-result-work col-2 px-0" *ngIf="item.careType.longWalk || item.careType.shortWalk || item.careTiming.weekdays || item.careTiming.weekends"> -->
    <div class="divide-result-work col-2 px-0"
      *ngIf="item.careTiming.walk || item.careTiming.weekdays || item.careTiming.weekends || item.careTiming.night">
      <hr class="my-2 mb-2">
    </div>
    <div class=""
      [ngClass]="{'four-items-small': (item.careTiming.walk && item.careTiming.weekdays && item.careTiming.night)}">
      <div class="list-amentiy">
        <ul class="list-unstyled m-0 p-0 d-flex">
          <!-- <li *ngIf="item.careType.longWalk || item.careType.shortWalk"> -->
          <li class="cursor-pointer" *ngIf="item.careTiming.walk" [matTooltipPosition]="'above'"
            [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.WALK' | translate"
            [matTooltipClass]="'mat-tooltip-for-icon'">
            <span class="color-gray fs-14 font-weight-normal">
              {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.WALK' | translate }}
              <img class="ml-1" width="12" src="assets/img/ic_paw.svg" alt="FairDogs_Hunde"></span>
          </li>
          <li class="cursor-pointer" *ngIf="item.careTiming.weekdays" [matTooltipPosition]="'above'" [matTooltip]="('PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.WEEKDAY' | translate) +
                        ((item.careTiming.duringDay || item.careTiming.evening) ? ',' : '') +
                        (item.careTiming.duringDay ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.SUN' | translate) : '') + 
                        ((item.careTiming.duringDay) && (item.careTiming.evening) ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.AND' | translate) : '') + 
                        (item.careTiming.evening ? ('PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.MOON' | translate) : '')
                        " [matTooltipClass]="'mat-tooltip-for-icon'">
            <span class="color-gray fs-14 font-weight-normal">
              {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.WEEKDAYS' | translate }}
              <!-- <img width="12" class="ml-1" src="assets/img/ic_paw.svg" alt=""> -->
              <img *ngIf="item.careTiming.duringDay" class="ml-1" src="assets/img/sun_first.svg" alt="FairDogs_Sun">
              <img *ngIf="item.careTiming.evening" class="ml-1" src="assets/img/moon_second.svg" alt="FairDogs_Moon">
            </span>
          </li>
          <!-- <li class="cursor-pointer" *ngIf="item.careTiming.weekends || item.careTiming.night" -->
          <li class="cursor-pointer" *ngIf="item.careTiming.weekends" [matTooltipPosition]="'above'"
            [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.WEEKEND' | translate"
            [matTooltipClass]="'mat-tooltip-for-icon'">
            <span class="color-gray fs-14 font-weight-normal">
              {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.WEEKEND' | translate }}
              <img width="12" class="ml-1" src="assets/img/ic_paw.svg" alt="FairDogs_Hunde">
            </span>
          </li>
          <li class="cursor-pointer" *ngIf="item.careTiming.night" [matTooltipPosition]="'above'"
            [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.TOOLTIP.VACATION' | translate"
            [matTooltipClass]="'mat-tooltip-for-icon'">
            <span class="color-gray fs-14 font-weight-normal">
              {{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.VACATION' | translate }}
              <img width="12" class="ml-1" src="assets/img/ic_paw.svg" alt="FairDogs_Hunde">
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="participant || (!participant && (item.distance >= 0))"
    class="d-flex align-items-center bottom-cta-result card-action-bottom bg-C7E5EF">
    <div *ngIf="!participant && (item.distance >= 0)"
      class="d-flex align-items-center justify-content-between text-truncate mw-100">
      <div class="d-flex align-items-center">
        <div class="location-km-img">
          <img src="assets/img/ic_gray-loaction.svg" alt="FairDogs_Location">
        </div>
        <div>
          <p class="m-0 color-gray fs-12">
            <span class="d-inline-block mr-1">
              <ng-container *ngIf="item.distance > 1; else meterBlock">
                {{item.distance | number: '1.0-0'}}km
              </ng-container>
              <ng-template #meterBlock>
                {{(item.distance*1000) | number : '1.0-0'}}m
              </ng-template>
            </span>{{'PAGE.DOG_CARE.DOG_SHARING.SHARED.PERSON_CARD.FROM_YOU' | translate }}
          </p>
        </div>
      </div>
      <div *ngIf="false" class="status-symbol status-offline d-none">
        <p class="m-0 status-dot-dynamic fs-12">offline</p>
      </div>
      <div class="text-truncate mw-100" *ngIf="!item?.displayLocation">
        <p class="m-0 color-gray fs-12 text-nowrap d-flex flex-nowrap"
          [ngClass]="{'content-seperate': !item?.displayLocation}">
          <ng-container
            *ngIf="(item.user?.location?.city || item.user?.location?.state) && item.user?.location?.countryName; else elseBlock">
            <ng-container *ngIf="item.user?.location?.city">
              <span class="text-truncate location-width">{{item.user?.location?.city}}</span>,
            </ng-container>
            <ng-container *ngIf="item.user?.location?.state">
              <span class="pl-1 text-truncate location-width">{{item.user?.location?.state}}</span>,
            </ng-container>
            <span class="pl-1">{{item.user?.location?.countryName}}</span>
          </ng-container>
          <ng-template #elseBlock> <span>{{item.user?.country}}</span> </ng-template>
        </p>
      </div>
    </div>
    <div class="d-flex ml-auto" *ngIf="participant">
      <button class="all-edit-btn justify-content-center align-items-center d-flex"
        [matTooltipPosition]="'above'"
        [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.TOOLTIP.SHOW_PROFILE' | translate"
        [matTooltipClass]="'mat-tooltip-for-icon'"
        (click)="onPersonPreviewDialog(item._id)"
      >
        <img src="assets/img/eye-blue.svg" alt="FairDogs_BlueEye">
      </button>
      <button class="all-edit-btn justify-content-center align-items-center d-flex"
        [matTooltipPosition]="'above'"
        [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.TOOLTIP.CHANGE_PROFILE' | translate"
        [matTooltipClass]="'mat-tooltip-for-icon'"
        (click)="onPersonEdit(item._id)"
      >
        <img src="assets/img/ic_edit.svg" alt="FairDogs_Edit">
      </button>
      <!-- deletePublication(item._id,item.type, item.active)   manageDeletePublicationPopup(item._id,item.type, item.active) -->
      <button class="all-edit-btn justify-content-center align-items-center d-flex mr-0"
        [matTooltipPosition]="'above'"
        [matTooltip]="'PAGE.DOG_CARE.DOG_SHARING.SHARED.DOG_CARD.TOOLTIP.DELETE_PROFILE' | translate"
        [matTooltipClass]="'mat-tooltip-for-icon'"
        (click)="manageDeletePublicationPopup(item._id,item.type, item.active)"
      >
        <img src="assets/img/ic_delete_gray.svg" alt="FairDogs_Delete">
      </button>
    </div>
  </div>
</div>