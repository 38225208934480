import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './auth/services/auth.service';
import { PurchaseProductsAction } from './profile/shared/store/credit/credit.actions';
import { CreditState } from './profile/shared/store/credit/credit.state';
import { Store } from '@ngxs/store';
import { ToastrNotificationService } from '@core/services/toastr-notification.service';
import { ProductService } from './profile/shared/services/product.service';

export let dogpersonalitytestquestions: Array<any> = [];
export let dogGraphTextData: Array<any> = [];

export let peoplepersonalitytestquestions: Array<any> = [];
export let peopleGraphTextData: Array<any> = [];

export const APP_ERROR_CODE = {
  PAYMENT_INCOMPLETE: 'PAYMENT_INCOMPLETE',
};

@Injectable()
export class Constants {

  private isFromExternal = false;
  private productId: string = '';

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private authService: AuthService,
    public productService: ProductService,
    public toastr: ToastrNotificationService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.isFromExternal = params['isExternal'] == 'true';
    });
  }

  public isLandscape(): boolean {
    return window.innerHeight < window.innerWidth;
  }

  getSubscriptionPlan() {
    const isAuth = this.authService.isAuthenticated();

    if(isAuth) {
      this.productService
        .getSubscriptionPlanByType({
          type: 'allInclusivePurchase',
          subType: 'allInclusivePurchase',
        })
        .subscribe(
          (res: any) => {
            if (res['success']) {
              this.productId = res.data[0].sku
            }
          },
          (error: any) => {
            this.toastr.showHttpError(error);
            return throwError(error);
          }
        );
    }
  }

  purchaseMembership () {
    const isAuth = this.authService.isAuthenticated();
    if(!isAuth) this.router.navigate([this.isFromExternal ? '/login' : '/register'])

    const data = {
      product: this.productId,
      successUrl: `${environment.wpApiUrl}/danke-fur-deinen-einkauf`,
      moveToUrl: true
    };
    this.store.dispatch(new PurchaseProductsAction(data)).subscribe(
      () => {
        const link = this.store.selectSnapshot(CreditState.link);
        (window as any).open(link, '_self');
      },
      (errObj) => {
        this.toastr.showError('', errObj?.error?.error?.data?.default || "Something went wrong!")
       }
    )
  }

  /**
   * get dog personality test question answers
   */
  getDogPersonalityQuestions() {
    this.httpClient.get("assets/json/dogpersonalitytestquestions.json").subscribe((data: any) => {
      dogpersonalitytestquestions = data;
    })
  }


  /**
   * get people personality test question answers
   */
  getPeoplePersonalityQuestions() {
    this.httpClient.get("assets/json/peoplepersonalitytestquestions.json").subscribe((data: any) => {
      peoplepersonalitytestquestions = data;
    })
  }


  /**
   * get dog personality test graph
   */
  getDogPersonalityGraphText() {
    this.httpClient.get(`${environment.baseApiUrl}/user/testGraphDesc?type=dogTest`, {}).subscribe((data: any) => {
      dogGraphTextData = data?.data;
    })
  }

  /**
 * get people personality test graph
 */
  getPeoplePersonalityGraphText() {
    this.httpClient.get(`${environment.baseApiUrl}/user/testGraphDesc?type=personTest`, {}).subscribe((data: any) => {
      peopleGraphTextData = data?.data;
    })
  }

}

export enum COUNTRY_CODES {
  USA = 'US',
  EUROPE = 'EU',
  SWITZERLAND = 'CH',
}

export const COUNTRIES_WITH_INDEX = {
  [COUNTRY_CODES.SWITZERLAND]: 1,
  [COUNTRY_CODES.EUROPE]: 2,
  [COUNTRY_CODES.USA]: 3,
};

export const PROFILE_TYPE = {
  DOG: 'dog',
  PERSON: 'person',
} as const;

export type ProfileType = typeof PROFILE_TYPE[keyof typeof PROFILE_TYPE];

export const DISPLAY_CARD_TYPE = {
  DOG: 'dog',
  PERSON: 'person',
  MEMBERSHIP: 'MEMBERSHIP',
} as const;

export type DisplayCardType = typeof DISPLAY_CARD_TYPE[keyof typeof DISPLAY_CARD_TYPE];

export const ADS_LOCATION = {
  WEBSITE_LANDING_PAGE: 'landingPage',
  APP_PUBLIC_DOGSHARING_PAGE: 'APP_PUBLIC_DOGSHARING_PAGE',
};

export const PRODUCT_TYPES = {
  ALL_INCLUSIVE_PURCHASE: 'allInclusivePurchase',
  CREDITS: 'credits',
  FREE_TRIAL_3D: 'FREE_TRIAL_3D',
} as const;